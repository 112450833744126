export enum AssetStateActions {
  fetchAssets = 'fetchAssets',
}

export enum AssetStateMutations {
  storeAllAssets = 'storeAllAssets',
  updateAssetIsFetched = 'updateAssetIsFetched',
  loadAssetOffers = 'loadAssetOffers',
  loadDiagramData = 'loadDiagramData',
  updateUserInvested = 'updateUserInvested',
  loadAssetRecords = 'loadAssetRecords',
  updateAsset = 'updateAsset',
  storeAsset = 'storeAsset',
  likeAsset = 'likeAsset',
}

export enum AssetOfferStatus {
  new = 'new',
  open = 'open',
  accepted = 'accepted',
  closed = 'closed',
  deleted = 'deleted',
}

export enum AssetOfferType {
  buy = 'buy',
  sell = 'sell',
  primary = 'primary',
}

export enum AssetOfferContactType {
  bulletin = 'bulletin',
  private = 'private',
}

export enum AssetDetailsInvestmentInfoComponent {
  RealEstateWithDebtCapital = 'RealEstateWithDebtCapital',
  RealEstateWithoutDebtCapital = 'RealEstateWithoutDebtCapital',
  RealEstateWithoutYieldPotential = 'RealEstateWithoutYieldPotential',
  PowerPlantData = 'PowerPlantData',
  PowerplantPortfolioData = 'PowerplantPortfolioData',
  BuilderOwnerModelData = 'BuilderOwnerModelData',
  CitizenParticipationLoanData = 'CitizenParticipationLoanData'
}

export enum InvestmentDetailsInfoComponent {
  AssetWithDebtCapital = 'AssetWithDebtCapital',
  AssetWithoutDebtCapital = 'AssetWithoutDebtCapital',
  AssetWithoutYieldPotential = 'AssetWithoutYieldPotential'
}

export enum RentDetailsInfoComponent {
  AssetWithDebtCapital = 'AssetWithDebtCapital',
  AssetWithoutDebtCapital = 'AssetWithoutDebtCapital',
  AssetWithoutYieldPotential = 'AssetWithoutYieldPotential'
}

export enum PowerPlantBarChartPeriod {
  year = 'year',
  week = 'week'
}
