export default {
  "AccountOverview": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYMENT ACCOUNT"])},
    "available_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available amount"])},
    "current_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current account balance"])},
    "past_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAST TRANSACTIONS"])},
    "pay_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "pay_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay out"])},
    "pending_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUTSTANDING TRANSACTIONS"])}
  },
  "AccountPlaceholder": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal transactions will be displayed here in future."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Informations"])}
  },
  "AccountView": {
    "invested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My investments"])},
    "new_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover new possibilities"])},
    "open_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open offers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])}
  },
  "AddPayoutAccountModal": {
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bic_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC copied to clipboard"])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with support!"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal IBAN"])},
    "iban_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN copied to clipboard"])},
    "info_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since it is your personal IBAN, you do not need to specify a specific reason for payment."])},
    "info_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your money usually arrives within two banking days."])},
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note the following"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add a payout account, you need to transfer at least 1 cent from your bank account to your payout account. This way we can ensure that the payout account is in your name. You can either invest the amount or pay it out again."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])}
  },
  "AddPayout": {
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add payout account"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add later"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can withdraw money, you must add a payout account."])}
  },
  "AreYouHappyWithTheAppModal": {
    "buttonNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "buttonYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you like our app?"])}
  },
  "AssetCard": {
    "asset_invested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invested"])},
    "assetSlider_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCOVER MORE"])},
    "borrowedCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "borrowedCapital_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Finanzprodukt hat einen integrierten Fremdkapitalanteil. Der/die Investor:in investiert dadurch in ein gehebeltes Produkt. Details zur Fremdfinanzierung sind unter “Investment Infos” zu finden."])},
    "builderOwnerModel_constructionStart_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp. building start"])},
    "builderOwnerModel_durationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended holding time:"])},
    "builderOwnerModel_durationValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["long-term"])},
    "constructionStatusProject_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt wurde noch nicht fertiggestellt und befindet sich aktuell in Bau."])},
    "constructionStatusStock_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei diesem Objekt handelt sich um ein Bestandsobjekt."])},
    "duration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity:"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endless"])},
    "expectedBuildingStartDate_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start of construction is scheduled for the specified date, but may be delayed."])},
    "expectedBuildingStartDate_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp. Building Start Date"])},
    "expected_rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributions exp. from:"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "kest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KESt"])},
    "kest_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen unterliegen der KESt, welche durch die Emittentin abgeführt wird."])},
    "minAmountPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Investment Amount"])},
    "minAmountPrice_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a minimum subscription amount for this asset. Investments can be made on the primary market starting from the specified amount."])},
    "minAmountPrice_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum subscription amount"])},
    "net_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental yield"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neu"])},
    "normalScenario_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal scenario"])},
    "partnerOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An offer of "])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "plannedSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["planned sale"])},
    "pricePerShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "pricePerShare_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares can be acquired starting from the specified price."])},
    "pricePerShare_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "primary_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding phase"])},
    "rentalDate_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird angegeben, ab wann  mit regelmäßigen Ausschüttungen gerechnet wird  bzw. wann voraussichtlich die nächste Ausschüttung erwartet wird."])},
    "rentalDate_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution"])},
    "rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp. next distribution:"])},
    "secondary_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading active"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
    "yieldPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential"])}
  },
  "AssetChart": {
    "last_price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price / share"])},
    "lastTransactionModal_firstParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price / share"])},
    "lastTransactionModal_firstParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the last price at which a share was traded on the marketplace."])},
    "lastTransactionModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart explanations"])},
    "lastTransactionModal_secondParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market value:"])},
    "lastTransactionModal_secondParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on a point in the chart, we display the total value of the asset. This is calculated on the basis of the total shares available multiplied by the respective moving average price at that point in time."])},
    "lastTransactionModal_thirdParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving average:"])},
    "lastTransactionModal_thirdParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the chart we show you the weighted average price of the last 30 days."])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "moving_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving average"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price / share"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
  },
  "AssetDetails": {
    "document_plantList_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plant list"])},
    "tab_myInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My investment"])},
    "tab_powerPlantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plant"])},
    "tab_realEstateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])}
  },
  "AssetFooter": {
    "documentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "legal_paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * The expected rental return is calculated on the basis of the net rental surplus over the net rent agreed in the lease and is only paid out if the tenant has paid it."])},
    "legal_paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An investment in the offered digital real estate shares does not constitute in registered property in the land register. The capital investment in the offered profit participation certificate is associated with considerable risks and can lead to the loss of the invested assets."])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "revenuePoolDocuments_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue Pool"])}
  },
  "AssetHighlights": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlights"])}
  },
  "AssetInfoCard": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "rentalYield_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental yield"])},
    "returnOnEquity_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
  },
  "AssetInfo": {
    "document_expertProtocol_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert protocol"])},
    "document_landRegister_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land register"])},
    "document_utilityReport_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value appraisal"])},
    "energy_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENERGY REQUIREMENTS"])},
    "floor_plans_and_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLOOR PLAN"])},
    "object_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Info"])},
    "powerPlant_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power plant infos"])},
    "realEstate_buildingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building type"])},
    "realEstate_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
    "realEstate_floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "realEstate_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Info"])},
    "realEstate_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
    "realEstate_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "realEstate_structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure"])},
    "realEstate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Info"])},
    "realEstate_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])}
  },
  "AssetInfoFooter": {
    "expert_protocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert protocol"])},
    "land_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land register"])},
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCUMENTS ABOUT THE OBJECT"])},
    "useful_value_appraisal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value appraisal"])}
  },
  "AssetMenu": {
    "asset_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Info"])},
    "asset_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "investment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "location_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])},
    "menu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All details"])},
    "powerPlant_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power plant infos"])},
    "powerplant_rentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running earnings"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "rent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview"])},
    "rentInfo_returnOnEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview and calculation"])}
  },
  "AssetOverview": {
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power in kwp"])},
    "constructionStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected construction start date"])},
    "correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "detailsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "durationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacant"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endless"])},
    "leverageOnDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage on day"])},
    "leverageOnDayModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This financial product has an integrated debt component. The investor thereby invests in a leveraged product. Details on external financing can be found under \"Investment Info\"."])},
    "leverageOnDayModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt"])},
    "leverageVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage volume"])},
    "leverageVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This financial product has an integrated debt component. As a result, the investor invests in a leveraged product. Details about the debt financing can be found under \"Investment Information.\""])},
    "leverageVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt component"])},
    "marketCapitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Capitalization"])},
    "marketCapitalizationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market capitalization is calculated based on the total available shares multiplied by the last price at which a share was traded on the marketplace."])},
    "marketCapitalizationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Capitalization"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market value"])},
    "mediumScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium scenario"])},
    "minInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Investment Amount"])},
    "next_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment on (day)"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "notCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "overallSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total asset"])},
    "payOutPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Potential"])},
    "payOutPotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment potential"])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "performancePotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Potential"])},
    "performancePotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance potential"])},
    "plannedSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned sale"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional Loan"])},
    "realPart_totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Volume"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. rental yield"])},
    "rentalStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental start date"])},
    "rentalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental status"])},
    "repaymentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment amount"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An investment in the offered digital real estate shares does not constitute in registered property in the land register. The capital investment in the offered profit participation certificate is associated with considerable risks and can lead to the loss of the invested assets."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential scenarios"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available shares"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total usable area"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Shares"])},
    "totalVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Volume"])},
    "totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total volume"])},
    "totalVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The market capitalization is calculated on the basis of the total available shares multiplied by the last price at which a share was traded on the marketplace. The debt portion indicates the open loan amount."])},
    "totalVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total volume"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])},
    "until_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
    "withoutChart_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as all shares from the issuer have been sold, the historical development is displayed here."])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
    "yieldPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield Potential"])}
  },
  "AssetRating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This property has been appraised by an independent appraiser."])}
  },
  "AssetRentInfo": {
    "document_rentalAgreement_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Agreement"])},
    "expected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributions expected from"])},
    "liquidity_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidity reserve"])},
    "management_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management costs"])},
    "net_rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net rental income"])},
    "net_rental_surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net rental income"])},
    "occupancy_rate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following ratio provides information on the average period per year in which the property was rented."])},
    "occupancy_rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OCCUPANCY RATE"])},
    "powerplant_rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running earnings"])},
    "progress_bar_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past information does not allow any conclusions to be drawn about future developments."])},
    "property_management_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property management costs"])},
    "rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview"])},
    "rental_return_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The expected rental return is calculated on the basis of the net rental surplus over the net rent agreed in the lease and is only paid out in each case if the tenant:in has paid it."])},
    "rental_return_text_bambus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The expected current return is calculated on the basis of the usage fee agreed in the co-ownership contract less the share for the issuer and is only paid out if the usage fee is paid by the co-owner."])},
    "rental_return_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental return p.a."])}
  },
  "AssetSideContent": {
    "moreAssetsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More assets"])},
    "myInvestmentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Investment"])}
  },
  "AssetSlider": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCOVER MORE"])}
  },
  "AssetView": {
    "chart_lastTransaction_modal_firstParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price / share"])},
    "chart_lastTransaction_modal_firstParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the last price at which a share was traded on the marketplace."])},
    "chart_lastTransaction_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart explanations"])},
    "chart_lastTransaction_modal_secondParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market value:"])},
    "chart_lastTransaction_modal_secondParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on a point in the chart, we display the total value of the asset. This is calculated on the basis of the total shares available multiplied by the respective moving average price at that point in time."])},
    "chart_lastTransaction_modal_thirdParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving average:"])},
    "chart_lastTransaction_modal_thirdParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the chart we show you the weighted average price of the last 30 days."])},
    "info_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate"])},
    "mobile_add_to_cart_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "mobile_add_to_cart_sell_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "not_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "offer_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My investment"])},
    "popup_header_notlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to invest in real estate."])},
    "popup_header_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is not yet fully verified!"])},
    "popup_text_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will inform you by e-mail as soon as we have checked all your data."])},
    "popup_text_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the verification process before making your first investment."])},
    "popup_underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already have an account"])},
    "powerPlant_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power plant"])},
    "purchase_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy more shares"])},
    "realEstate_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate"])},
    "review_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This property has been appraised by an independent appraiser."])},
    "share_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out this real estate investment on Brickwise!"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify account"])},
    "withoutChart_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as all shares from the issuer have been sold, the historical development is displayed here."])}
  },
  "BankAccountVerification": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])},
    "info_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank data reconciliation via instant transfer"])},
    "info_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are legally required to verify your identity before you can invest."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue later"])}
  },
  "Biometrics": {
    "button_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Face ID"])},
    "button_finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Touch ID"])},
    "face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Face ID?"])},
    "finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Touch ID?"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in faster without having to re-enter your password every time."])}
  },
  "BuyOfferOverview": {
    "buy_contact_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private contact"])},
    "buy_contact_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following contact details have been provided so that the seller(s) can contact you regarding payment processing."])},
    "buy_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance of the request by the seller"])},
    "buy_finish_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for the release of the sellers until the desired shares are transferred."])},
    "buy_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payment"])},
    "buy_payment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay the total amount of"])},
    "buy_payment_body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the seller"])},
    "buy_payment_body_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received."])},
    "buy_payment_release_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete purchase"])},
    "buy_payment_release_final_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the Security App to complete the purchase."])},
    "buy_payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successful"])},
    "buy_released": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy with obligation to pay"])},
    "buy_released_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to sign the purchase offer."])},
    "buy_released_body_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purchase offer was signed with the Security App."])},
    "buy_released_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction"])},
    "buy_released_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction"])},
    "buy_released_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase offer signed"])},
    "buy_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction"])},
    "buy_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the payment has been made, you can release the purchase of the shares by clicking the button below."])},
    "buy_seller_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance of the offer by an investor"])},
    "buy_seller_new_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A notification will be sent as soon as there is interest in the purchase offer from existing investors."])},
    "buy_seller_open_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Someone is willing to sell you the shares you want."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit offer?"])},
    "delete_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete offer"])},
    "private_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon completion of the sale, a brokerage fee of"])},
    "private_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is debited from the payment account."])},
    "private_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaciton"])},
    "private_release_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the payment has been made, you can confirm the purchase of the shares by clicking the button below."])},
    "private_release_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction"])},
    "private_release_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction"])},
    "process_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction of 5 shares has successfully taken place and are now in your possession."])},
    "purchase_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase offer"])},
    "seller_released_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The seller has released the transaction. The shares are now in your possession."])},
    "seller_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval form the seller"])},
    "seller_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as the seller has also approved the transaction, the shares are transferred."])},
    "sell_private_contact_first_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following contact details have been provided so that the seller can contact you regarding payment processing."])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transfer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to your payment account:"])},
    "view_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View investment"])}
  },
  "ComingSoon": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is only available in the app. Please download the app to continue."])}
  },
  "CompanyRegistration": {
    "emailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid"])},
    "input_addressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "input_emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "input_nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "input_phoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "input_registerNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company register number"])},
    "input_taxNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company registration"])},
    "phoneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "primaryButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "secondaryButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a natural person instead"])}
  },
  "ContinueOnMobile": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile App"])},
    "headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue the verification process in the"])},
    "mobileWebDownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the Appstore"])},
    "mobileWebHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue the verification process in the"])},
    "mobileWebParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To verify your identity, you will need to provide validation through a valid document. Kindly download our mobile app to initiate the verification process. This app will guide you through the necessary steps to ensure your identity is successfully confirmed."])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To verify your identity, you will need to provide validation through a valid document. Kindly download our mobile app to initiate the verification process. This app will guide you through the necessary steps to ensure your identity is successfully confirmed."])}
  },
  "CountriesModal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "CreateAccount": {
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go forward"])}
  },
  "Day": {
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])}
  },
  "DesktopSecurityAppIntegration": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’v conntected the security app"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven’t connected to the security app, please connect to be able to continue. "])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with the security app"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Brickwise Security App or create your own"])}
  },
  "Documents": {
    "plantList_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plant List"])}
  },
  "DownloadApp": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open App Store"])},
    "mobileNotSupported_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This platform is currently only available on the desktop. Please open the application on a larger screen."])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this code with your phone to download the PLATFORM app to your phone."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app"])}
  },
  "EnergyImpact": {
    "card_shareProductionPerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One share produces ca."])},
    "card_withElCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a electrical car"])},
    "co2_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation is done on an example with a formula by IBC Solar"])},
    "elCar_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The calculation is based on the assumption of a standard consumption of 15.4 kWh per 100 km, which according to the ADAC corresponds to the consumption of a Tesla Model 3 (as of 14 April 2023)."])},
    "householdConsumption_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein durchschnittlicher österreichischer Haushalt verbraucht 4.415 kWh elektrische Energie pro Jahr (Quelle: Österreichs E-Wirtschaft, Stand 2023)."])},
    "modal_avgHouseholdConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption of an average household with 4,500 kWh."])},
    "modal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can calculate what contribution your investment will make to the energy transition and what opportunities it will open up."])},
    "modal_eCarDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive an electric car"])},
    "modal_expectedCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO₂ savings (in tonnes)"])},
    "modal_expectedGeneratedEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected energy generated p.a."])},
    "modal_lightBulbHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
    "modal_numOfShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of shares"])},
    "modal_phoneChargingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of mobile phone charging"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contribution to a sustainable future"])},
    "modal_tvHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch TV or Netflix"])},
    "modal_washMachineCircles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry"])},
    "modal_whereCanBeSpent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This could also be used to"])},
    "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per Year."])},
    "whereCanBeSpent_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Stromverbrauch wird wie folgt angenommen:\nPro Waschgang: 0,75kWh, Pro gefahrenen 100km 15,4kWh, Pro Stunde Licht (60Watt), Jahresverbrauch Handyladen 5kWh, Pro Stunde Netflix 0,2kWh."])}
  },
  "Enum": {
    "AssetStage": {
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing"])},
      "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding phase"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading active"])}
    },
    "BuildingConditionType": {
      "massive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massive"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "newDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New development"])},
      "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old"])},
      "renovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation"])}
    },
    "ConstructionStatus": {
      "connectedToNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to Network"])},
      "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed"])},
      "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])}
    },
    "ConstructionType": {
      "integrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated"])},
      "landsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landsite"])},
      "roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roof"])}
    },
    "ContractType": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
      "notRented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Rented"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
    },
    "HeatingType": {
      "central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
      "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric"])},
      "floorCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor heating"])},
      "heatpump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heat pump"])},
      "naturalGas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Gas"])},
      "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil"])},
      "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar"])},
      "woodPellet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WoodPellet"])}
    },
    "PowerPlantType": {
      "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Infrastructure"])},
      "photovoltaic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photovoltaic"])},
      "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind"])}
    },
    "PropertyType": {
      "apartmentBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "atticFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attic Flat"])},
      "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
      "cooperativeFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative Flat"])},
      "demolitionNewConstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demolition - new construction"])},
      "duplexHouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplex House"])},
      "familyHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
      "firstFloorFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Floor Flat"])},
      "flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat"])},
      "loft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loft"])},
      "loftConversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loft Conversion"])},
      "partialFamilyHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial Family Home"])},
      "pentHouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penthouse"])},
      "rawAtticFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw Attic Floor"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
      "studioFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Flat"])}
    },
    "SecurityType": {
      "participationAtypicalSilent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atypical silent participation"])},
      "participationKG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG share"])},
      "participationRightDebtCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate (debt)"])},
      "participationRightDebtCapitalPartialSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate (debt; partial sale)"])},
      "participationRightEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate (equity)"])},
      "participationRightEquityBuilderOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate (equity, builder-owner model)"])},
      "participationRightSubordinatedLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subordinated loan"])},
      "purchaseWithManagementOutsourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase with management outsourcing"])}
    },
    "TransactionMethods": {
      "applePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Pay"])},
      "bankWire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
      "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])},
      "directDebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct debit"])},
      "googlePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Pay"])},
      "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
    },
    "TransactionStatuses": {
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
      "canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled offer "])},
      "charged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charged"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Init"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "TransactionTaxType": {
      "KapESt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital gain tax (KapESt)*"])},
      "KESt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital gain tax (KESt)*"])},
      "Kirchensteuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church tax*"])},
      "Soli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solidarity surcharge* "])}
    },
    "TransactionTypes": {
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
      "charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge"])},
      "financing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financing "])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
      "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividend"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])}
    }
  },
  "ExternalIdentification": {
    "backToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the App"])},
    "loginIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with ich."])},
    "orLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed now"])},
    "proceedIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled out with ich."])},
    "registerIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with ich."])}
  },
  "ExternalSignature": {
    "codeConfirmation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, the code you entered is incorrect."])},
    "codeConfirmation_send_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the code again"])},
    "codeConfirmation_sms_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now sign your documents. We have sent a code to:"])},
    "codeConfirmation_sms_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "codeConfirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "electronicSignatureInfo_failed_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with your verification"])},
    "electronicSignatureInfo_failed_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I need help"])},
    "electronicSignatureInfo_failed_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "electronicSignatureInfo_pending_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! Verifying your data will take longer"])},
    "electronicSignatureInfo_pending_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I need help"])},
    "electronicSignatureInfo_pending_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll get back to you as soon as you can continue with your registration."])},
    "electronicSignatureInfo_pending_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the marketplace"])},
    "electronicSignatureInfo_pending_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also come back later..."])},
    "electronicSignatureInfo_skip_for_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue later"])},
    "electronicSignatureInfo_success_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently verifying your information"])},
    "electronicSignatureInfo_success_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be redirected shortly..."])},
    "fourthlineRequirementsModal_gpsRequired_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share location again"])},
    "fourthlineRequirementsModal_gpsRequired_errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't access your location. Please check in the settings whether the Brickwise app is authorized to access your location."])},
    "fourthlineRequirementsModal_gpsRequired_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, you will need to share your location again to complete your verification."])},
    "fourthlineRequirementsModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "fourthlineRequirementsModal_selfieRequired_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake selfie"])},
    "fourthlineRequirementsModal_selfieRequired_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, you will need to take another selfie to complete your verification."])},
    "partnerApp_signatureDocuments_firstItem_subtitle_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
    "partnerApp_signatureDocuments_firstItem_subtitle_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- App is a platform operated by Brickwise Investment GmbH."])},
    "partnerApp_signatureDocuments_firstItem_title_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator of the"])},
    "partnerApp_signatureDocuments_firstItem_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- App"])},
    "signatureDocuments_firstItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms of use as well as the customer information and risk information of the Brickwise Investment GmbH."])},
    "signatureDocuments_firstItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "signatureDocuments_firstItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information & risk warnings"])},
    "signatureDocuments_firstItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "signatureDocuments_fourthItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms of use of Namirial S. p. A. and the SafeNed-Fourthline B.V. privacy policy."])},
    "signatureDocuments_fourthItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "signatureDocuments_fourthItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection policy"])},
    "signatureDocuments_fourthItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualified electronic signature"])},
    "signatureDocuments_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "signatureDocuments_secondItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms and conditions and customer information of Effecta GmbH."])},
    "signatureDocuments_secondItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "signatureDocuments_secondItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
    "signatureDocuments_secondItem_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In accordance with § 3 (2) WpIG Brickwise Investment GmbH is a contractually tied agent of Effecta GmbH (Florstadt), which is supervised by the Federal Financial Supervisory Authority."])},
    "signatureDocuments_secondItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial services institution"])},
    "signatureDocuments_thirdItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms of use for payment services of Mangopay SA."])},
    "signatureDocuments_thirdItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Framework Agreement for Payment Services"])},
    "signatureDocuments_thirdItem_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to be able to make deposits and withdrawals, as well as to be able to offer you various payment options, a payment account in your name will be opened at Mangopay."])},
    "signatureDocuments_thirdItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment service provider"])},
    "signatureDocuments_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement"])},
    "signatureVerificationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete verification"])},
    "signatureVerificationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can start, you have to accept and sign our agreements."])},
    "signatureVerificationInfo_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verificationFinalModal_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully verified!"])},
    "verificationFinalModal_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have fun investing."])},
    "verificationFinalModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest now"])}
  },
  "FinancialAdvisor": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermögensberater"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Vermögensberater"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie ggf. Ihren Vermögensberater bekanntgeben."])}
  },
  "FinancingOverview": {
    "currentlyInvested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested so far"])},
    "fundingLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding Limit"])},
    "fundingThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding Threshold"])}
  },
  "ForgotPasswordView": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation code"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your email."])},
    "emailCheck_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your email."])},
    "emailCheck_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your email to reset your password."])},
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered e-mail address is invalid."])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password forgotten"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address to reset your password."])},
    "info_code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you a code to"])},
    "info_code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["so that you can reset your password."])},
    "info_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a new password with at least 10 characters and one uppercase letter."])},
    "info_password_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a new password."])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "no_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not receive a code?"])},
    "password_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 10 characters and one capital letter"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "send_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])},
    "sent_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation code was sent again!"])},
    "set_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
    "set_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
    "success_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now log back into your account with your new password."])},
    "success_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have set a new password."])},
    "to_brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to login"])},
    "token_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered code is incorrect."])},
    "too_many_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons we have temporarily blocked this function for you. Please contact our support at support", "@", "brickwise.at"])}
  },
  "Forum": {
    "activeVotingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active voting"])},
    "addAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attachment"])},
    "closedVotes_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Votes"])},
    "compliant_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Content"])},
    "compliant_sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
    "compliant_textPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message to operator..."])},
    "compliant_titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regarding"])},
    "createOptionModal_addNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachment"])},
    "createOptionModal_descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "createOptionModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Option erstellen"])},
    "createOptionModal_publishOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "createOptionModal_titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option title"])},
    "createVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Vote"])},
    "dateAtTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "downloads_file1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachverständigenprotokoll"])},
    "downloads_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuch"])},
    "downloads_file3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzwertgutachten"])},
    "downloads_file4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "downloads_file5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietvertrag"])},
    "downloads_file6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsdatenblatt"])},
    "downloads_file7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "downloads_file8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "downloads_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "forumTextArea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
    "headerMenu_closedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Votes"])},
    "headerMenu_complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
    "headerMenu_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "headerMenu_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])},
    "headerMenu_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Notifications"])},
    "rule1_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut zu wissen:"])},
    "rule1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Forum dient nur zur Kommunikation unter den Investor:innen."])},
    "rule2_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte halte dich an folgende Regeln:"])},
    "rule2_point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Diskriminierung und Diffamierung"])},
    "rule2_point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Störung der Diskussion"])},
    "rule2_point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine falschen oder illegale Beiträge"])},
    "rule2_point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Veröffentlichung von privaten Informationen"])},
    "rulesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to the forum"])},
    "rules_footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen an Brickwise oder die Emittentin werden hier nicht beantwortet und können gerne jederzeit an support", "@", "brickwise.at gesendet werden."])},
    "rulesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor:innen Forum"])},
    "rulesSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Forum wir von der SPV_NAME bereitgestellt."])},
    "startVote_addFIle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file"])},
    "startVote_addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new option"])},
    "startVote_createOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Options"])},
    "startVote_goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "startVote_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new vote"])},
    "startVoteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new voting"])},
    "startVote_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startVote_optionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "startVote_optionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "startVote_submitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish voting"])},
    "startVote_submitErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Bitte versuche es nochmal oder kontaktiere den Support."])},
    "startVote_submitFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as the vote has been published, it will be included in the voting suggestions"])},
    "startVote_submitSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit das Voting zur Abstimmung gelangt, müssen mindestens 10% der Stimmanteile und 10% der Investor:innen innerhalb von 48 Stunden das Voting unterstützen."])},
    "startVote_theme1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss und Kündigung von Miet- oder Pachtverträgen"])},
    "startVote_theme2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Verwaltung der Immobilie (z.B. Hausverwaltung)"])},
    "startVote_theme3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Instandhaltung oder Instandsetzung der Immobilie (z.B. Handwerker)"])},
    "startVote_theme4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Vermittlung von Miet- oder Pachtverträgen (z.B. Makler)"])},
    "startVote_theme5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufnahme von Krediten zur Finanzierung von Instandhaltungen"])},
    "startVote_theme6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])},
    "startVote_voteDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "startVote_voteTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Theme"])},
    "startVote_voteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "startVote_voteTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote topic"])},
    "subVote_agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
    "subVote_approval_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
    "subVote_approval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval"])},
    "subVote_progressbarLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order for this vote to be voted on, at least 10% of the voting shares and 10% of the investors must support the vote."])},
    "subVote_progressbarLabelTextNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The proposal was not able to convince the necessary 10% of investors"])},
    "subVote_progressbarLabelTextYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The proposal was able to convince the necessary 10% of investors and will therefore be put to a vote."])},
    "voteDone_abstainedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votes"])},
    "voteDone_abstentionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will abstain from voting"])},
    "voteDone_againstAllButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am against all options"])},
    "voteDone_againstAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am against all options"])},
    "voteDone_agreementText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuer will inform the shareholders as soon as the requested action has been carried out."])},
    "voteDone_doneHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished Voting"])},
    "voteDone_ongoingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Vote"])},
    "voteDone_rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vote was rejected by the majority."])},
    "voteFuture_addNewOptionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your own option"])},
    "voteOverview_agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support vote"])},
    "voteOverview_suggestionDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order for this vote to be voted on, at least 10% of the voting shares and 10% of the investors must support the vote. If this goal is achieved, a vote will be held."])},
    "voteOverview_suggestionFailedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The proposal was not able to convince the necessary 10% of investors."])},
    "voteOverview_suggestionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion for Voting"])},
    "voteOverview_suggestionSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⏰ Vote open"])},
    "voteOverview_suggestionSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The proposal was able to convince the necessary 10% of investors and will therefore be put to a vote."])},
    "votingMessage_approvalReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✅ Zustimmung erhalten"])},
    "votingMessage_completedIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚡️ Abgeschlossen"])},
    "votingMessage_noConsentReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🚫 Keine Zustimmung erhalten"])},
    "votingMessage_ongoingVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufendes Voting"])},
    "votingMessage_openUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⏰ Geöffnet"])},
    "votingMessage_proposalForVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschlag für Voting"])},
    "votingMessage_upcomingVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehendes Voting"])},
    "votingOverview_futureVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Votes"])},
    "votingOverview_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting Overview"])},
    "votingOverview_runningVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running Votes"])},
    "votingOverview_suggestedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Votes"])}
  },
  "ForumContent": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor: innen Forum"])}
  },
  "General": {
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "nextPayout_quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "nextPayout_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])}
  },
  "GermanTaxIdModal": {
    "churchMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you member of a church?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to invest in this product, we require a German tax identification number and details of your church affiliation."])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest in another product"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tax ID is required for this product"])}
  },
  "HeaderNotification": {
    "identification_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can invest, you need to fully verify your account."])},
    "identification_waiting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will inform you by e-mail as soon as we have checked all your data."])},
    "registration_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can invest, you need to create a Brickwise account."])},
    "securityApp_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can invest, you need to connect your security app."])},
    "signature_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to complete the last step of your account creation."])}
  },
  "Ich möchte folgenden Betrag veranlagen:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available investment capital"])},
  "IdentificationExternal": {
    "verificationFailed_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong!"])},
    "verificationFailed_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I need help"])},
    "verificationFailed_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "verificationFailed_red_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The photo you took (selfie) is too bright. Please take a new photo where you are clearly visible."])},
    "verificationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now verify your identity with an ID document!"])},
    "verificationInfo_bold_fourthline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID photo and selfie\n"])},
    "verificationInfo_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue later"])},
    "verificationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are legally required to verify your identity before you can invest."])},
    "verificationInfo_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])},
    "verificationModal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue later"])},
    "verificationModal_fourthline_first_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "verificationModal_fourthline_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have your ID with you?"])},
    "verificationModal_fourthline_second_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card"])},
    "verificationModal_fourthline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the verification process, you will be asked to take a photo of yourself and an ID document that we can use to verify your identity."])},
    "verificationModal_fourthline_third_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver's license"])},
    "verificationModal_idNow_first_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "verificationModal_idNow_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have your ID with you?"])},
    "verificationModal_idNow_second_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card"])},
    "verificationModal_idNow_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification will take place using the IDnow VideoIdent procedure. As part of the this video chat, you need one of the following ID documents:"])},
    "verificationModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start verification"])}
  },
  "IdentificationPersonalInfo": {
    "address_city_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "address_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your address is not complete."])},
    "address_houseNumber_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "address_onlyAustrianCitizens_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, only natural persons resident in Austria can register."])},
    "address_postcode_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "address_street_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "address_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "already_provided_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the registration process and open a support ticket if you wish to change the phone number."])},
    "already_provided_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number has already in use."])},
    "autocompleteAddress_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "birthday_age_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise is only available to persons over the age of 18."])},
    "birthday_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD"])},
    "birthday_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your date of birth."])},
    "birthday_input_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your date of birth is incorrect."])},
    "birthday_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "birthday_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
    "birthday_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY"])},
    "birthPlace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth place"])},
    "clearForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset address data"])},
    "duplicate_user_popup_error_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have an account?"])},
    "enter_manually_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual input"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go forward"])},
    "ibanNumber_apiErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN not valid (API)"])},
    "ibanNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungskonto"])},
    "ibanNumber_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "ibanNumber_invalidIbanErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN not valid"])},
    "ibanNumber_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib für zukünftige Auszahlungen den persönlichen IBAN an."])},
    "nationality_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "nationality_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "phoneError_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact support."])},
    "phoneError_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error saving the phone number."])},
    "phoneNumber_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers are allowed."])},
    "phoneNumber_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number"])},
    "phoneNumber_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "phoneNumber_modal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
    "phoneNumber_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])},
    "phoneNumber_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To continue please log into your account. If you have forgotten your password, click on forgot password or contact support."])},
    "phoneNumber_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cell phone number is already in use with another account."])},
    "phoneNumber_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "phoneWrong_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the phone number and try again."])},
    "phoneWrong_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number is incorrect."])},
    "requiredFiles_moneyLaundering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The legal transaction serves neither money laundering nor the financing of terrorism"])},
    "requiredFiles_my_own_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I run this account in my own name and for my own account."])},
    "requiredFiles_pep_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politically Exposed Person (PEP)"])},
    "requiredFiles_pep_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A politically exposed person (PEP) is a person who either holds or has held a high-ranking important public office (e.g. politician, ambassador, judge of supreme courts), or a person from their immediate personal environment (e.g. family members or close relatives)."])},
    "requiredFiles_political_person_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm, that I am not a"])},
    "requiredFiles_political_person_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "requiredFiles_political_person_underlined_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politically exposed person"])},
    "requiredFiles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "requiredFiles_us_resident_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATCA law"])},
    "requiredFiles_us_resident_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a result of the \"FATCA\" law enacted in 2010, U.S. persons are required to report their financial accounts outside the U.S. on an annual basis. At the same time, financial institutions outside the U.S. are required to register U.S. persons. Due to the requirements defined in FATCA, investors subject to U.S. tax liability cannot invest via Brickwise."])},
    "requiredFiles_us_tax_resident_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm, that I am not "])},
    "requiredFiles_us_tax_resident_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "requiredFiles_us_tax_resident_underlined_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxable in the US"])},
    "smallBusinessOwner_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the client/are you a small business owner within the meaning of § 19 UStG?"])},
    "smallBusinessOwner_respondNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "smallBusinessOwner_respondYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "socialNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermögensberater"])},
    "socialNumber_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Vermögensberater"])},
    "socialNumber_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie ggf. Ihren Vermögensberater bekanntgeben."])}
  },
  "IdentificationRegister": {
    "brickwiseApp_marketing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH will process my name and email address to send me marketing messages via email about its own or similar products. If this is not desired, marketing messages can be unsubscribed using the following toggle, in the settings or directly in each email."])},
    "companyRegisterModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the possibility to register as a company or natural person."])},
    "companyRegisterModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as Company or natural person?"])},
    "companyRegisterModal_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a company"])},
    "companyRegisterModal_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a natural person"])},
    "emailConfirmation_bold_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your email"])},
    "emailConfirmation_light_text_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you a confirmation link to"])},
    "emailConfirmation_light_text_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "emailConfirmation_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open email"])},
    "emailConfirmation_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't get an email?"])},
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address appears to be invalid."])},
    "email_privacy_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find our"])},
    "email_privacy_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection policy"])},
    "email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "notReceivedEmail_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
    "notReceivedEmail_first_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email was sent to:"])},
    "notReceivedEmail_first_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is your email address correct?"])},
    "notReceivedEmail_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't get an email?"])},
    "notReceivedEmail_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email again"])},
    "notReceivedEmail_second_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you should find it there, just mark our email as not spam."])},
    "notReceivedEmail_second_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your spam folder"])},
    "notReceivedEmail_third_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may take a while for our email to arrive in your inbox."])},
    "notReceivedEmail_third_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait a moment"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a platform operated by Brickwise Investment GmbH"])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Platform"])},
    "partnerApp_marketing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The platform operator will process my name and email address in order to send me marketing messages by email about its own or similar products. If this is not desired, marketing messages can be unsubscribed using the following toggle, in the settings or directly in each email."])},
    "registration_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a company"])},
    "registration_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "registration_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "registration_name_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "registration_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "registration_password_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "registration_password_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must have at least 10 characters."])},
    "registration_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "registration_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as an individual"])},
    "toggle_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive updates"])},
    "web_Email_headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address?"])},
    "web_Email_headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What’s your"])},
    "web_NamePassword_headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your name and password"])},
    "web_NamePassword_headerStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Imprint": {
    "alternative_conflict_resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative dispute resolution body"])},
    "company_purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business purpose"])},
    "company_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company register"])},
    "conflict_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association for arbitration for consumer transactions"])},
    "conflict_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mariahilferstrasse 103/1/18, 1060 Vienna;"])},
    "conflict_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone +43 1 890 63 11,"])},
    "conflict_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax +43 1 890 63 11 99,"])},
    "conflict_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday - Thursday: 09:00 - 17:00, Friday: 09:00 - 15:00."])},
    "conflict_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.verbraucherschlichtung.at"])},
    "document_privacyPolicy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy statement"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "legal_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information and disclosure according to § 25 MedG and § 5 RCG"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director:"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number:"])}
  },
  "ImprintDetails": {
    "address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amalienstraße 71, 2. RGB"])},
    "address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80799 München"])},
    "address_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "brickwise_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail: hello", "@", "brickwise.at"])},
    "director_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing director: Marco Neumayer"])},
    "district_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amtsgericht München, HRB 269358 UID: DE347734642"])},
    "fifth_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store und das App Store-Logo sind Marken von Apple Inc.Google Play und das Google Play-Logo sind Marken von Google LLC."])},
    "fifth_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further legal notices:"])},
    "first_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object of the Company is the brokerage of financial instruments via an Internet service platform as a contractually bound broker exclusively for the account and under the liability of a securities institution pursuant to Section 3 (2) WpIG."])},
    "first_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business purpose"])},
    "fourth_section_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information on this website is promotional. Each article on the website is a marketing communication and is intended as a stimulus and information medium. It does not claim to provide comprehensive information and merely reflects the opinion of the author(s):in. The contents of the article are therefore for information purposes only and do not constitute investment, legal, tax or other advisory services. The content, graphics and text presented in the article is therefore not an investment recommendation."])},
    "fourth_section_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The capital investment is associated with risks. The value of a capital investment can rise as well as fall. This may result in losses of the capital invested. Past performance, simulations or forecasts are not reliable indicators of future performance. Please note our risk information."])},
    "fourth_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Online Dispute Resolution Platform"])},
    "partner_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a platform operated by Brickwise Investment GmbH. "])},
    "second_section_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BaFin Registration No.: 80174029"])},
    "second_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH isa tied agent in the sense of § 3 (2) WpIG and acts in the brokerage of financial instruments according to § 2 (2) No. 3 WpIG exclusively for the account and under the liability of Effecta GmbH, Florstadt. Brickwise Investment GmbH is registered with BaFin as a tied agent."])},
    "second_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note according to § 3 (2) WplG "])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information and disclosure according to § 5 TMG"])},
    "third_section_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://ec.europa.eu/consumers/odr/"])},
    "third_section_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The European Commission under the following link"])},
    "third_section_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["established a European online dispute resolution platform (ODR platform). The ODR platform can be used by a consumer for the out-of-court settlement of a dispute arising from online contracts with a company established in the EU."])},
    "third_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Online Dispute Resolution Platform"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])}
  },
  "ImprintFooter": {
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA PROTECTION"])}
  },
  "Inbox": {
    "emptyHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no messages!"])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy the silence - there will be a lot going on here soon."])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "readAllNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read All TODO"])}
  },
  "InfoCards": {
    "calculator_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return calculator"])},
    "calculator_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate your investment & your return potentials"])},
    "howItWorks_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is how it works"])},
    "howItWorks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current returns and performance?"])},
    "referral_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit from every recommendation"])},
    "referral_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund:innen einladen & Bonus kassieren"])}
  },
  "IntroImages": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open account"])}
  },
  "IntroSlide1": {
    "login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])}
  },
  "Investment": {
    "abort_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel transaction"])},
    "annual_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual rental return"])},
    "asset_performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
    "asset_performance_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \"Next\" I confirm that I will invest no more than twice my average monthly net income (calculated over 12 months), or no more than 10% of my financial assets."])},
    "austrianSocialNumber_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only natural persons who are resident in Austria for tax purposes are eligible to participate. A tax number or national insurance number is required to submit the relevant declarations."])},
    "austrianSocialNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax-Number or SVNR (Austria)"])},
    "austrianSocialNumber_orLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "austrianSocialNumber_socialNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnummer (NNNNTTMMJJ)"])},
    "austrianSocialNumber_taxNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "bonus_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BONUS CODE"])},
    "brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "builderOwnerModelOverview_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest now"])},
    "builderOwnerModelOverview_companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "builderOwnerModelOverview_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price is a net price. Personal VAT will also be charged with the invoice. This price is therefore subject to change. After the purchase, a payment request will be issued for the transfer of the investment amount."])},
    "builderOwnerModelOverview_investmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invesitionsbetrag"])},
    "builderOwnerModelOverview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy shares"])},
    "buildingInvestment_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Amount"])},
    "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Price"])},
    "buy_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy shares"])},
    "cancel_transaction_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to cancel the transaction?"])},
    "code_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem bonus code"])},
    "continue_with_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "create_buy_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create purchase offer"])},
    "create_sell_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create sales offer"])},
    "create_sell_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign offer"])},
    "digitalSignature_emptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no signature in the field!"])},
    "effecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effecta GmbH"])},
    "file_checkbox_underline_fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost information"])},
    "file_checkbox_underline_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information sheet"])},
    "file_checkbox_underline_fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer information"])},
    "file_checkbox_underline_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment conditions"])},
    "file_checkbox_underline_third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sheet"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "goToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Account"])},
    "investmentAmount_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "investment_terms_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you have read and accepted all the terms and conditions."])},
    "investment_terms_newsletter_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Notifications"])},
    "investmentTerms_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "investment_terms_underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH is a tied agent according to § 3 (2) WpIG of Effecta GmbH, Florstadt."])},
    "investor_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers from Investors"])},
    "investor_modal_text_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all offers from other investors who offer their shares for sale. Alternatively, you can also create your own offer."])},
    "investor_modal_text_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all offers from other investors who want to buy shares. Alternatively, you can also create your own offer."])},
    "investor_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers from investors"])},
    "issuer_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer offer"])},
    "issuer_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can buy shares directly from the issuer at the issue price. The issuer is the owner of the land register who issues the profit participation certificates for this property."])},
    "issuer_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not that many shares are available."])},
    "issuer_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer offer"])},
    "k_and_e_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \"Continue purchase\" I confirm that I have been informed that the purchase of the product, based on my previous experience and knowledge, does not correspond to my investment profile. Nevertheless, I decide to purchase this product."])},
    "k_and_e_bottom_text_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update details"])},
    "k_and_e_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue purchase"])},
    "k_and_e_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appropriateness is judged by whether you have the necessary knowledge and experience to adequately assess the risks associated with the type of financial instruments or securities services."])},
    "k_and_e_first_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adequacy"])},
    "k_and_e_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important notes"])},
    "k_and_e_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you continue, we would like to draw your attention to the following important points."])},
    "k_and_e_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the information you provided, we are sorry to say that this product or investment amount does not match your investment profile."])},
    "k_and_e_second_text_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you have not provided or have provided incomplete information about your previous experience and knowledge. Therefore, we are not able to verify the appropriateness of your investment. You must therefore decide for yourself whether the product and the investment amount are appropriate for you."])},
    "k_and_e_second_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge & Experience"])},
    "k_and_e_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please refer to the investment terms and conditions or basic information about the design and risk structure of the product."])},
    "k_and_e_third_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk structure of the product"])},
    "maxSubscriptionAmount_part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum Investment amount of"])},
    "maxSubscriptionAmount_part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per user has been reached."])},
    "menu_buy_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy shares"])},
    "minumumAmount_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum subscription amount for this asset is"])},
    "minumumAmount_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shares"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "noOffers_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new offer to buy shares from other investors."])},
    "noOffers_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers"])},
    "notaryAppointment_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "notaryAppointment_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Time"])},
    "notaryAppointment_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment will now be forwarded to the notary's office, which will contact you within the next 48 hours with further instructions."])},
    "notaryAppointment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one of the following notary appointments"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not so many shares available."])},
    "not_enough_money_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have enough money in your payment account."])},
    "not_enough_money_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up payment account"])},
    "not_enough_money_modal_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "not_enough_money_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use your Brickwise payment account, you need to fund it with a transfer from your bank account. You can find your personal IBAN in the Brickwise app under \"Account\". Your money will normally arrive within two banking days."])},
    "offerCard_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
    "offerCard_max_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. available"])},
    "offerCard_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per share"])},
    "offerCard_possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible"])},
    "offerCard_price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price / share"])},
    "offerCard_profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
    "offerCard_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended rental yield p.a"])},
    "offerCard_rent_distribution_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent distribution per year"])},
    "offerCard_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "offerCard_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "offerCard_yieldPotential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yieldpotential (YYJ)"])},
    "offer_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer overview"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is operated by Brickwise Investment GmbH, a contractually tied agent according to § 3 (2) WpIG of Effecta GmbH, Florstadt."])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The platform"])},
    "over_5k_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You invest over € 5.000"])},
    "over_5k_modal_header_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue purchase"])},
    "over_5K_modal_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "over_5k_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \"Next\" I confirm that I will invest no more than twice my average monthly net income (calculated over 12 months), or no more than 10% of my financial assets."])},
    "overview_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH ist Betreiberin dieser Plattform und vertraglich gebundener Vermittler gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "partnerInvestmentSuccessScreen_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_button"])},
    "partnerInvestmentSuccessScreen_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_text1 TODO"])},
    "partnerInvestmentSuccessScreen_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_text2 TODO"])},
    "payment_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay conveniently with your payment account, or choose from other payment options:"])},
    "paymentRequest_accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "paymentRequest_amountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "paymentRequest_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange online Notarty appointment"])},
    "paymentRequest_ibanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "paymentRequest_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next step you will be asked to make an appointment with an online notary to sign a power of attorney."])},
    "paymentRequest_purposeOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "paymentRequest_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please transfer your investment amount to the following account:"])},
    "paymentRequest_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have emailed you your investment documents"])},
    "possible_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected loss"])},
    "possible_profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible profit"])},
    "priceInfo_10PercentHigher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your desired price is over 10% higher than the average price over the last 30 days."])},
    "priceInfo_10PercentLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your desired price is over 10% lower than the average price over the last 30 days."])},
    "priceInfo_20Percent_BuyModal_mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue purchase"])},
    "priceInfo_20Percent_BuyModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm purchase price"])},
    "priceInfo_20PercentHigher_BuyModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your desired price is over 20% higher than the average price over the last 30 days. Please confirm that you want to buy at this price."])},
    "priceInfo_20PercentHigher_SellModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your desired price is over 20% higher than the average price over the last 30 days. Please confirm that you want to sell at this price."])},
    "priceInfo_20PercentLower_BuyModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your offered price is over 20% lower than the average price in the last month. Please confirm that you want to continue with the purchase."])},
    "priceInfo_20PercentLower_SellModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your offered price is over 20% lower than the average price in the last month. Please confirm that you want to continue selling."])},
    "priceInfo_20Percent_Modal_closeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "priceInfo_20Percent_SellModal_mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue sale"])},
    "priceInfo_20Percent_SellModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm sale price"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price / Share"])},
    "purchase_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMISSION FEE"])},
    "purchaseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Klick auf “Kauf fortsetzen” bestätige ich, dass ich darüber informiert wurde, dass der Kauf des Produktes, aufgrund meiner bisherigen Erfahrungen und Kenntnisse, nicht meinem Anlageprofil entspricht. Dennoch entscheide ich mich für den Kauf dieses Produktes."])},
    "purchase_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase offer"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PURCHASE PRICE"])},
    "purchase_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase overview"])},
    "realpartAccountOpening_firstCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_firstCheckbox_label TODO"])},
    "realpartAccountOpening_secondCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_secondCheckbox_label TODO"])},
    "realpartAccountOpening_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_subtitle TODO"])},
    "realpartAccountOpening_thirdCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_thirdCheckbox_label TODO"])},
    "realpartAccountOpening_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_title TODO"])},
    "realpartAccountOpening_toggleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_toggleLabel TODO"])},
    "realPart_KE_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angemessenheit beurteilt sich danach, ob die Risikobereitschaft, der Veranlagungshorizont und das verfügbare Veranlagungskapital mit der beabsichtigten Investition übereinstimmen.\n\nAufgrund der Angaben sind wir der Ansicht, dass dieses Produkt bzw. die Investitionshöhe nicht dem Anlageprofil entspricht."])},
    "realPart_KE_firstTile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemessenheit"])},
    "realPart_KE_firstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appropriateness"])},
    "realPart_KE_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise"])},
    "realPart_KE_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir weisen auf folgende wichtige Punkte hin:"])},
    "realPart_KE_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as Informationsblatt und die Risikohinweise enthalten alle Informationen über die Ausgestaltung und Risikostruktur des Produkts."])},
    "realPart_KE_secondTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikostruktur des Produktes"])},
    "remove_code_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove bonus code"])},
    "rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental income"])},
    "rental_payout_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental income"])},
    "rental_payout_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every month and when the tenant of the property has paid rent, you will receive your rent share. Your rent share is transferred to your payment account and we will notify you in the Brickwise app. You can find the exact calculation of the rent surplus for each property under the menu item rent overview."])},
    "rental_return_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The expected rental return is calculated on the basis of the net rental surplus over the net rent agreed in the lease and is only paid out in each case if the tenant:in has paid it."])},
    "rental_return_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental return p.a."])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "returnOnEquityModal_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal scenario"])},
    "sale_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALE PRICE"])},
    "sales_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale offer"])},
    "sales_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales overview"])},
    "scenarioDocument_label_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten auf die von der Emittentin kalkulierten"])},
    "scenarioDocument_label_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential scenarios"])},
    "scenarioDocument_label_third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinweisen."])},
    "secondaryOfferCard_potentialEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Profit:"])},
    "secondaryOfferCard_sharesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
    "secondaryOfferCard_totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "security_app_action_sheet_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "security_app_action_sheet_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't want to sign the purchase?"])},
    "security_app_action_sheet_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel purchase"])},
    "security_app_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
    "security_app_buy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to sign the purchase."])},
    "security_app_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "security_app_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need a security app to keep your digital real estate shares safe and to digitally sign all transactions of digital real estate shares. You can either use the Brickwise Security App or check out the API documentation here to create your own security app."])},
    "security_app_sell_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to sign the sale."])},
    "sell_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will inform you as soon as there is a purchase request for your offer to complete the sale."])},
    "sell_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign sale"])},
    "sell_offer_total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
    "sell_overview_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the buyer signs the transaction, the amount is credited to your payment account."])},
    "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALE PRICE"])},
    "sell_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell shares"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "shares_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "signDocuments_attorneyContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollmacht zum Betriebsführungsvertrag TODO"])},
    "signDocuments_attorneyContractSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe die Vollmacht TODO"])},
    "signDocuments_operatingAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating agreement"])},
    "signDocuments_operatingAgreementSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Betriebsvertrag TODO"])},
    "signDocuments_purchaseContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase contract"])},
    "signDocuments_purchaseContractSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Kaufvertrag TODO"])},
    "signDocuments_subtenantAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtenancy agreement"])},
    "signDocuments_subtenantAgreementSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Untermietvertrag TODO"])},
    "signDocuments_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign document"])},
    "sign_purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign purchase"])},
    "successScreen_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Marktplatz "])},
    "successScreen_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment will be arranged with the notary's office, who will contact you within the next 48 hours with further instructions."])},
    "successScreen_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment arranged"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
    "total_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL REVENUE"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
  },
  "InvestmentBonus": {
    "btn_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the marketplace"])},
    "bullet1_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 100 Bonus"])},
    "bullet1_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to € 1.000 investment"])},
    "bullet2_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 550 Bonus"])},
    "bullet2_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to € 5.000 investment"])},
    "bullet3_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 1.200 Bonus"])},
    "bullet3_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to € 10.000 investment"])},
    "durationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.12.2022."])},
    "durationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer valid until:"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIRTHDAY BONUS"])},
    "underline_buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])}
  },
  "InvestmentDetails": {
    "allScenarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All scenarios\n\n\n\n\n"])},
    "builderOwnerModel_sharePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of each share"])},
    "calculationAndRentalOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation and Rent Overview"])},
    "calculationAndRentalOverview_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current income overview and detailed calculation"])},
    "collateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collateral"])},
    "collateralModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collateral"])},
    "constructionStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected construction start date"])},
    "debtFinancing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt financing"])},
    "distribution_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributions"])},
    "document_investmentBasicInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information sheet"])},
    "document_investmentConditions_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment conditions"])},
    "document_investmentInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information data sheet"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endless"])},
    "expected_rent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "financial_instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial instrument"])},
    "financialInstrumentModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The products can have different legal structures. These in turn may have different characteristics in terms of term, cancellation rights, transferability, etc. Further clarifying information can be found in the investment terms. "])},
    "financialInstrumentModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial instrument"])},
    "header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "leverageOnDay_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage on day"])},
    "leverageVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage volume"])},
    "marketCapitalization_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Capitalization"])},
    "marketCapitalizationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market capitalization is calculated based on the total available shares multiplied by the last price at which a share was traded on the marketplace."])},
    "marketCapitalizationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Capitalization"])},
    "mediumScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "mortgage_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collateralization pending in land register"])},
    "mortgage_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate secured by land register entry"])},
    "object_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object"])},
    "optimisticScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimistic"])},
    "originalLoanAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Loan Amount"])},
    "outstandingLoanAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Loan Amount"])},
    "overallSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "periodConsiderationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The observation period specifies the timeframe for which the return potential of the invested equity is calculated.\n\n\n\n\n"])},
    "periodConsiderationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation period"])},
    "periodUnderConsideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation period"])},
    "powerPlant_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution overview and calculation"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional Loan"])},
    "realEstate_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview and calculation"])},
    "realPart_totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Volume"])},
    "rentalStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental start date"])},
    "rentIndexation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent indexing"])},
    "repaymentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment amount"])},
    "returnPotential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential"])},
    "returnPotentialScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential scenarios"])},
    "returnPotentialScenario_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following overview illustrates how various asset performance scenarios can impact the yield potential. In all scenarios, rent indexation and different asset performance scenarios are assumed."])},
    "stressScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stress"])},
    "taxInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation Information"])},
    "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total usable area"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total shares"])},
    "totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Volume"])},
    "totalVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The market capitalization is calculated on the basis of the total available shares multiplied by the last price at which a share was traded on the marketplace. The debt portion indicates the open loan amount."])},
    "totalVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total volume"])},
    "valueDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
    "yearsSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])}
  },
  "InvestmentInfo": {
    "about_the_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT THE ISSUER"])},
    "available_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available shares"])},
    "debt_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt ratio"])},
    "document_investmentBasicInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information sheet"])},
    "document_investmentConditions_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment conditions"])},
    "document_investmentInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information data sheet"])},
    "expected_rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution"])},
    "expected_rent_date_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "financial_instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial instrument"])},
    "investment_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "isin_box_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN and share name"])},
    "isin_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "isin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ISIN, short for International Securities Identification Number, is an international identification number by which securities can be clearly identified."])},
    "KEStLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
    "KEStValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributions are subject to capital gains tax (KESt) "])},
    "maturityDate_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of term"])},
    "mortgage_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collateralization pending in land register"])},
    "mortgage_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificate secured by land register entry"])},
    "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional Loan"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental yield"])},
    "rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next distribution"])},
    "repaymentAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment amount per share at the end of the term"])},
    "shares_on_blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares on the Blockchain"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares Total"])},
    "transaction_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction costs"])},
    "value_paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY"])},
    "value_paper_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuer of the security is the owner of the real estate in the land register. The issued securities are profit participation certificates and digitized on the blockchain Brickprotocol."])},
    "value_paper_text_bambus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuer is the registered owner of the co-ownership share of the property in question. The securities issued are participation certificates and digitized on the Blockchain Brickprotocol. The term of the security is unlimited. In addition to ordinary termination options (after 15 years), the issuer also has extraordinary termination options if the co-owner buys back the co-ownership share at the proportional market value (possible at any time) or the co-owner does not meet his obligations under the co-ownership agreement and the co-ownership share or the entire property is sold by the issuer whoever is being sold. See also Section 10.3 in particular. the investment conditions."])}
  },
  "InvestmentInfoFooter": {
    "basic_info_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information sheet"])},
    "information_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information data sheet"])},
    "investment_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment conditions"])},
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOADS"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* The expected rental return is calculated on the basis of the net rental surplus over the net rent agreed in the lease and is only paid out in each case if the tenant has paid it."])}
  },
  "InvestmentOverviewBox": {
    "builderOwnerModel_totalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Investment"])},
    "total_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total rental surplus"])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio market value"])},
    "value_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change in value"])}
  },
  "Investments": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase offer"])},
    "buy_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT shares"])},
    "buy_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT share"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "investmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My investment"])},
    "price_per_share_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["each"])},
    "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue"])},
    "sale_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-sale"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer for sale"])},
    "sell_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT shares"])},
    "sell_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT share"])},
    "webMyInvestmentsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Investments"])},
    "webNoInvestmentsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where your investments could stack up."])},
    "webNoOpenOffersPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open offers"])},
    "webOpenOffersSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My open offers"])}
  },
  "IssuerMarketing": {
    "consent_info_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My above consents to communications are not necessary for the conclusion of the purchase and I can revoke them at any time with effect for the future towards "])},
    "consent_info_fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "consent_info_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by post ("])},
    "consent_info_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([") or per E-Mail ("])},
    "dataTransfer_consent_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree that"])},
    "dataTransfer_consent_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my name, address, e-mail, telephone number and purchase history (number and type of shares purchased) can be transferred to"])},
    "dataTransfer_consent_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to contact me by e-mail and telephone for marketing purposes regarding their own or similar products. "])},
    "dataTransfer_consent_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacting by"])},
    "email_consent_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company"])},
    "email_consent_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will process my name and email address to send me marketing messages by email about its own or similar products. If you do not want this, remove the checkmark in the adjacent checkbox or click on the unsubscribe link at the end of a marketing message you receive."])},
    "email_consent_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive news"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH is a tied agent according to § 3 (2) WpIG of Effecta GmbH, Florstadt. "])},
    "phone_marketing_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent that"])},
    "phone_marketing_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may process my name and telephone number in order to contact me by telephone to promote its own or similar products. "])},
    "phone_marketing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal contact by phone"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data privacy"])},
    "profiling_marketing_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent that"])},
    "profiling_marketing_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may process my name, my email address and my behavior in the App in order to send me personalized marketing messages (based on my behavior in the App) about its own or similar products by email."])},
    "profiling_marketing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized messages (\"profiling\")"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages from"])}
  },
  "IssuerSecurity": {
    "about_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the issuer"])},
    "blockchain_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares on the Blockchain"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuer of the security is the owner of the property in the land register. The securities issued are profit participation certificates and digitized on the Brickprotocol blockchain."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN, short for International Securities Identification Number, is an international identification number that can be used to clearly identify securities."])},
    "investmentCompany_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Company"])},
    "isin_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "isin_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN and Share Name"])},
    "shareNameISIN_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN and Share Name"])},
    "shareNameISIN_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN and Share Name"])},
    "shareName_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Name"])},
    "shareName_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Name"])}
  },
  "KnowledgeAndExperience": {
    "experienceInfo_fifthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment funds"])},
    "experienceInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stocks"])},
    "experienceInfo_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificates"])},
    "experienceInfo_modal_fistItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to 3 years"])},
    "experienceInfo_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long is your experience with this product?"])},
    "experienceInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 5 years"])},
    "experienceInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["over 5 years"])},
    "experienceInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonds"])},
    "experienceInfo_sixthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "experienceInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all the financial products below that you already have experience with."])},
    "experienceInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digital securities"])},
    "experienceInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your experience"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "investmentServicesInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDEPENDENT / FREE OF CONSULTATION"])},
    "investmentServicesInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "investmentServicesInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 5 years"])},
    "investmentServicesInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 3 years"])},
    "investmentServicesInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 5 years"])},
    "investmentServicesInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "investmentServicesInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WITH INVESTMENT ADVICE"])},
    "investmentServicesInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WITH ASSET MANAGEMENT"])},
    "investmentServicesInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have experience with investment services?"])},
    "keInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge and experience"])},
    "keInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State what knowledge and experience you have with various financial instruments and investment services. You can also continue without providing any information."])},
    "knowledgeInfo_fifthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stocks"])},
    "knowledgeInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment funds"])},
    "knowledgeInfo_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit participation certificates"])},
    "knowledgeInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonds"])},
    "knowledgeInfo_sixthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digital securities"])},
    "knowledgeInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all the financial products below that you already have knowledge about."])},
    "knowledgeInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "knowledgeInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your knowledge"])},
    "later_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't state"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "skipFlowModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "skipFlowModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't want to provide any information?"])},
    "skipFlowModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \"Next\" I confirm that I am aware that the missing information does not allow an examination of the adequacy of my knowledge in relation to financial products and that I am acting at my own risk."])},
    "transactionInvestInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 0 to € 3,000"])},
    "transactionInvestInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over € 10.000"])},
    "transactionInvestInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€3,001 to €5,000"])},
    "transactionInvestInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 5,001 to €10,000"])},
    "transactionInvestInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "transactionInvestInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much do you invest per transaction?"])},
    "transactionsAmountInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "transactionsAmountInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 10"])},
    "transactionsAmountInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to  5"])},
    "transactionsAmountInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 10"])},
    "transactionsAmountInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "transactionsAmountInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many transactions do you make each year?"])},
    "webHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge and Experience"])}
  },
  "KnowledgeAndExperienceNew": {
    "basicQuestions_bonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleihen"])},
    "basicQuestions_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
    "basicQuestions_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "basicQuestions_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience & knowledge"])},
    "basicQuestions_kgParticipationAsBuildersModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG-Beteiligung als Bauherrenmodell"])},
    "basicQuestions_knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge"])},
    "basicQuestions_optionsAndFutures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen und Futures"])},
    "basicQuestions_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "basicQuestions_savingsAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparbuch, Bausparvertrag"])},
    "basicQuestions_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "basicQuestions_stocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktien und Fonds"])},
    "basicQuestions_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have knowledge and experience with the following products"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "investHorizon_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzfristig 1-4 Jahre"])},
    "investHorizon_20+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langfristig mehr als 20 Jahre"])},
    "investHorizon_6_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittelfristig 6-19 Jahre"])},
    "investHorizon_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Veranlagung für folgenden Zeithorizont machen?"])},
    "investHorizon_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranlagungshorizont"])},
    "investHorizon_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "investHorizon_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "investHorizon_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Veranlagung für folgenden Zeithorizont machen?"])},
    "investmentCapital_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbares Veranlagungskapital"])},
    "investmentCapital_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Amount"])},
    "investmentCapital_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "investmentCapital_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "investmentCapital_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte folgenden Betrag veranlagen:"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge And Experience"])},
    "riskAppetite_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please let us know how much risk you are prepared to take:"])},
    "riskAppetite_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobereitschaft"])},
    "riskAppetite_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringe Risikobereitschaft (z.B. Sparbuch, Bausparvertrag)"])},
    "riskAppetite_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlere Risikobereitschaft (z.B. Anleihen)"])},
    "riskAppetite_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhere Risikobereitschaft (z.B. Aktien, Fonds)"])},
    "riskAppetite_option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spekulativ (z.B. KG-Beteiligung als Bauherrenmodell, Zertifikate)'"])},
    "riskAppetite_option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochspekulativ (z.B. Optionen, Futures"])},
    "riskAppetite_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "riskAppetite_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "riskAppetite_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please let us know how much risk you are prepared to take:"])},
    "skipFlowModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "skipFlowModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't want to provide any information?"])},
    "skipFlowModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \\\"Next\\\" I confirm that I am aware that the missing information does not allow an examination of the adequacy of my knowledge in relation to financial products and that I am acting at my own risk."])}
  },
  "LinkNewSecurityApp": {
    "box1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I lost my old device"])},
    "box2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a new device"])},
    "box3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have reinstalled the app"])},
    "box4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to link another device"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you want to link a new security app?"])}
  },
  "LocationDetails": {
    "about_the_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT THE REGION"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCATION"])},
    "object_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])}
  },
  "LoginView": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change account?"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and e-mail address do not match."])},
    "error_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the password you entered is incorrect."])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten?"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered E-Mail ist not valid."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "use_face_biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with Face ID"])},
    "use_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with password"])},
    "use_touch_biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with Touch ID"])}
  },
  "MarketingNewsLetterModal": {
    "default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Notifications"])},
    "privacy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find our"])},
    "privacy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Privacy Terms"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get news on your investments."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Notifications"])}
  },
  "Marketplace": {
    "all_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "apartments_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential Real  Estate"])},
    "business_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
    "durationInfoModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products can have a defined term (with a due date) or be designed as endless financial products. A combination with different termination rights is also possible. Details can be found in the investment conditions under Investment Info."])},
    "durationInfoModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
    "favorite_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourites"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "fundedAssetsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More assets"])},
    "inConstruction_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "list_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH ist Betreiberin dieser Plattform und vertraglich gebundener Vermittler gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "list_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our properties"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
    "newAssetsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New assets"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is operated by Brickwise Investment GmbH, a contractually tied agent of Effecta GmbH, Florstadt, pursuant to § 3 (2) WpIG."])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The platform"])},
    "powerplantPortfolioConnectedToNetwork_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to network"])},
    "powerplantPortfolioInstalled_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed"])},
    "powerplantPortfolioPlanning_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "powerplantPortfolioProject_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "pre_sales_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-sale"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "rental_return_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The expected rental return is calculated on the basis of the net rental surplus over the net rent agreed in the lease and is only paid out in each case if the tenant:in has paid it."])},
    "rental_return_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental return p.a."])},
    "rental_yield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental yield"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An investment in the offered digital real estate shares does not constitute in registered property in the land register. The capital investment in the offered profit participation certificate is associated with considerable risks and can lead to the loss of the invested assets."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential scenarios"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "returnOnEquityModal_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal scenario"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "stock_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
  },
  "Menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "login_popup_button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])},
    "login_popup_button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already have an account"])},
    "login_popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to invest in real estate."])},
    "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])}
  },
  "Month": {
    "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
    "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])}
  },
  "MyInvestment": {
    "available_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available shares"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price per share"])},
    "average_price_secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ø purchase price per share"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day*"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days*"])},
    "duration_purchase_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining time"])},
    "erw_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected rental yield"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORUM OF THE OWNERS"])},
    "forum_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open forum"])},
    "forum_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make joint decisions about renovation, rent, and tenants in the investor forum. With other investors, you can participate in the decision on what happens with the property."])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["still"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
    "my_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My investment"])},
    "oekostrom_popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pre-sale phase is the period during which new real estate shares can be purchased by investors for the first time. After all real estate shares have been sold, the pre-sale phase is officially considered to be over. You can also tell if a property on Brickwise is a first time sale (issue) by the yellow \"New\" tag. After all available real estate shares have been sold on the primary market, they can be traded between investors on the secondary market."])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "past_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAST TRANSACTIONS"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the pre-sale phase?"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pre-sale phase is the period during which new real estate shares can be purchased by investors for the first time. After all real estate shares have been sold, the pre-sale phase is officially considered to be over. You can also tell if a property on Brickwise is a first time sale (issue) by the yellow \"New\" tag. After all available real estate shares have been sold on the primary market, they can be traded between investors on the secondary market."])},
    "procedure_purchase_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedure of the pre-sale phase"])},
    "profit_from_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit from the shares"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your rental surplus"])},
    "serialNumbers_noSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here will be list of serial numbers TODO"])},
    "serialNumbersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Numbers TODO"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shares"])},
    "shares_secured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares secured"])},
    "shars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "someHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today*"])},
    "timeline_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here."])},
    "timeline_note_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* The duration of the pre-sale period may be extended under certain circumstances. More details you will find"])},
    "timeline_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares of new real estate are always offered at a fixed price."])},
    "timeline_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pre-sale phase will be officially completed on "])},
    "timeline_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "timeline_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive monthly surpluses and trade your shares on the marketplace."])},
    "timeline_text_4_firstPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive monthly surpluses"])},
    "timeline_text_4_secondPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and trade your shares on the marketplace."])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current market value"])},
    "total_value_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total investment"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
    "value_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change in value"])}
  },
  "Navigation": {
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go forward"])}
  },
  "NetworkError": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline!"])},
    "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your internet connection to be able to use the Brickwise app."])},
    "toastText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your internet connection"])}
  },
  "NewSecurityApp": {
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINK SECURITY APP"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Security App"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To sign transactions, the account must be linked to a security app."])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Private Security App"])},
    "underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View API documentation"])}
  },
  "NoInvestments": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where your investments could stack up."])},
    "screen_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer money to your payment account or choose from other payment options:"])}
  },
  "NotaryAppointment": {
    "austrianId_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verwende bereits ID Austira?"])},
    "austrianId_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "austrianId_noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis bitte vor dem Notartermin ID Austria beantragenBitte beantragen Sie vor dem Notartermin eine ID Austria. Diese kann bei der Gemeinde, dem Magistrat, der Bezirkshauptmannschaft (BH) oder dem Finanzamt beantragt werden."])},
    "austrianId_noDescriptionMoreInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find more information about ID Austria"])},
    "austrianId_noDescriptionMoreInformationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "austrianId_noDescriptionRegistrationAuthorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find registration authorities"])},
    "austrianId_noDescriptionRegistrationAuthoritiesLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "austrianId_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "austrianId_yesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the validity of your ID Austria in advance. You can do this here, for example:"])},
    "austrianId_yesDescriptionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check signature"])},
    "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your appointment"])},
    "infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin wird nun an das Notariat weitergeleitet, das sich innerhalb der nächsten 48 Stunden mit weiteren Anweisungen melden wird."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one of the following notary appointments "])}
  },
  "NotFound": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Marketplace"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum text:  Brickwise Investment GmbH wird meinen Namen und meine E-Mail-Adresse verarbeiten, um mir Marketing-Nachrichten per E-Mail zu eigenen oder ähnlichen Produkten zuzusenden."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry this page doesn't exist any more"])}
  },
  "NotHappyWithTheAppModal": {
    "buttonCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "buttonOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can we do better?"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback...."])}
  },
  "Notifications": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable messages"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable messages"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll send you helpful reminders and messages about your Brickwise account."])}
  },
  "ObjectDetailsOverview": {
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market value"])},
    "noFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ground Floor"])},
    "price_per_sqm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro m²"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building type"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])}
  },
  "OpenOffersOverview": {
    "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open offers"])}
  },
  "Overview": {
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power in kwp"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacant"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market value"])},
    "next_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment on (day)"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. rental yield"])},
    "rentalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental status"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available shares"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])}
  },
  "PayInModal": {
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bic_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC copied to clipboard"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal IBAN"])},
    "iban_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN copied to clipboard"])},
    "info_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment purpose can be chosen freely, as this is a personal payment account. You will be notified as soon as the money is available."])},
    "info_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your money usually arrives within two banking days"])},
    "info_2_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also pay for your purchases conveniently and securely with your credit card at any time."])},
    "info_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay directly with your credit card"])},
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note the following"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to your  account"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])}
  },
  "PayInView": {
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN was copied to the clipboard!"])}
  },
  "PaymentRedirect": {
    "midText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the browser window to finalise the transaction"])}
  },
  "PayOutModal": {
    "amount_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "amount_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "amount_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "amount_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "code_confirmation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered code is wrong"])},
    "code_confirmation_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the code"])},
    "code_confirmation_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent a text-message to"])},
    "code_confirmation_sms_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout account"])},
    "max_amount_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have enough money available"])},
    "max_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay out full amount"])},
    "min_amount_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum payout amount is € 0,10."])},
    "transaction_confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
    "transaction_confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment confirmed"])}
  },
  "PersonalDocuments": {
    "brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "cost_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost information"])},
    "customer_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
    "effecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effecta GmbH"])},
    "general_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms and conditions"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My documents"])},
    "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay SA"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "service_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service contract"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])}
  },
  "PowerPlantBarChart": {
    "averagePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ø Production per day"])},
    "todayProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's production"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
  },
  "PowerPlantInfo": {
    "constructionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "constructionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction type"])},
    "lifespan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned period of use"])},
    "marketValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketvalue"])},
    "moduleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module type"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected from"])},
    "nominalPowerPerModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominal rated power kWp per module"])},
    "powerOutput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
    "powerOutputPerAnno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expec. generation per year (in kWh)"])},
    "powerPlantType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])},
    "propertySecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right of use"])},
    "rentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment on (day)"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "storageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Capacity"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power plant infos"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])}
  },
  "PrivateSecurityApp": {
    "brickwise_security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Brickwise Security App"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "connected_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with purchase offer"])},
    "connected_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connected."])},
    "connected_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY APP"])},
    "connected_private_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])},
    "connected_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You connected a private security app on the"])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link now"])},
    "failed_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check public key"])},
    "failed_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linking failed"])},
    "failed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: As described in the documentation, your wallet must have the provided signatures script. "])},
    "failed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, something went wrong when linking your private security app. Please check the given data and try again."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Private Security App"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With your private security app, you can only place and accept private offers on our marketplace. Therefore, not all offers will be visible to you. "])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your public key here to link your security app. If you have questions about this, check out our "])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API documentation"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])}
  },
  "ReadMore": {
    "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])}
  },
  "RealEstateInfo": {
    "buildingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building type"])},
    "doorNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door No."])},
    "energy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENERGY REQUIREMENTS"])},
    "equipment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
    "noFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ground Floor"])},
    "photos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLOOR PLAN"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Info"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])}
  },
  "RecordsView": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All current and past information and documents about the object are displayed here."])},
    "empty_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no records"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])}
  },
  "ReferralModal": {
    "acceptedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation successfully accepted!"])},
    "btn_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
    "btn_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's go"])},
    "bullet1_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send your invitation link"])},
    "bullet1_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your great joy about Brickwise"])},
    "bullet2_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Someone opens an account"])},
    "bullet2_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and completes the first investment"])},
    "bullet3_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You both get"])},
    "bullet3_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A joy shared is a joy doubled"])},
    "bullet4_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a Brickwise account"])},
    "bullet4_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the verification completely"])},
    "bullet5_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest in real estate"])},
    "bullet5_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the right investment for you"])},
    "bullet6_bold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive your"])},
    "bullet6_bold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonus"])},
    "bullet6_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bonus is credited directly"])},
    "inviteTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite a friend and collect"])},
    "inviteTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["each."])},
    "referralLinkCopiedWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Link Copied"])},
    "referralShare_dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends!"])},
    "share_dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends!"])},
    "share_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simply use this link to download the"])},
    "share_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-App and"])},
    "share_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receive a bonus on the first investment. "])},
    "share_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus for the first investment? "])},
    "terms_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By sharing the link, you agree to the"])},
    "terms_text_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions of participation"])},
    "terms_text_zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])}
  },
  "ReminderDialog": {
    "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow evening"])},
    "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow afternoon"])},
    "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind me later"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account creation can be continued later. When do you want to be reminded?"])}
  },
  "RemoveSecurityApp": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect security app"])},
    "checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have my recovery file (private key)"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To link a new security app to your account, you must first disconnect the existing one. "])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCONNECT SECURITY APP"])},
    "old_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect Security App"])},
    "old_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To link a new security app to your account, you must first disconnect the existing one. "])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCONNECT SECURITY APP"])},
    "unable_to_disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please write us so we can take care of your request."])},
    "underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellungsdatei nicht verfügbar?"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To link a new security app, you need your recovery file that you saved when you activated the security app."])}
  },
  "RentalYield": {
    "citizenParticipationLoan_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen p.a."])}
  },
  "RentDetails": {
    "administrative_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative cost"])},
    "currentDistributionYield_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Distribution Yield"])},
    "dissolutionLiquidityReserveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissolution of Liquidity reserve"])},
    "distribution_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttung"])},
    "distribution_yield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution yield"])},
    "distributionYield_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Potential"])},
    "distributionYield_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Yield"])},
    "document_rentalAgreement_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Agreement"])},
    "interestFees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest fees"])},
    "liquidity_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidity reserve"])},
    "management_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management costs"])},
    "net_rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Rental Income"])},
    "normalScenario_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal scenario"])},
    "payOutPotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment potential"])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "performance_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance potential"])},
    "performancePotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance potential"])},
    "performance_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
    "periodUnderConsideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation period"])},
    "powerplant_rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running earnings"])},
    "rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview"])},
    "rentInfo_returnOnEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental overview and calculation"])},
    "rentSurplusAfterInterest_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Rental Surplus after Interest"])},
    "rentSurplusBeforeInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Rental Surplus before Interest"])},
    "rentSurplus_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Surplus"])},
    "repayment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment"])},
    "returnOnEquity_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield potential"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An investment in the offered digital real estate shares does not constitute in registered property in the land register. The capital investment in the offered profit participation certificate is associated with considerable risks and can lead to the loss of the invested assets."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return potential scenarios"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "until_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
    "yearsSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])}
  },
  "RentInfoFooter": {
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCUMENTS"])}
  },
  "RentPeriod": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following rate provides information about the average period per year in which the property was rented."])},
    "progress_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The past information does not allow for conclusions about future developments."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy rate"])}
  },
  "SecurityAppIntegrationIdentification": {
    "privateSecurityApp_modal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View API documentation"])},
    "privateSecurityApp_modal_first_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can link your own security app to your Brickwise account.  If you haven't created your own security app yet, check out"])},
    "privateSecurityApp_modal_first_paragraph_second_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to code one."])},
    "privateSecurityApp_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your own security app"])},
    "privateSecurityApp_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your own security app"])},
    "privateSecurityApp_modal_second_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! With your own security app, you can only create and accept private offers."])},
    "privateSecurityApp_modal_underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API documentation"])},
    "securityAppIntegration_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect security app"])},
    "securityAppIntegration_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Brickwise Security App or create your own."])},
    "securityAppIntegration_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an own security app"])},
    "securityAppIntegration_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download app"])}
  },
  "SecurityAppOverlay": {
    "api_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "back_to_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the marketplace"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer ID Copied"])},
    "offer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer ID"])},
    "open_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the"])},
    "open_text1_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the following offer ID you can save the transaction in your private"])},
    "open_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "open_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to sign the offer."])},
    "open_text3_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to sign the offer."])},
    "open_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to sign the offer."])},
    "own_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the"])},
    "own_app1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API documentation if you want to use your own security app"])},
    "own_app1_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to make the transaction processing fast and uncomplicated."])},
    "own_app_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "secApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])}
  },
  "SecurityAppSettings": {
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connected"])},
    "connected_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the Brickwise Security App on"])},
    "header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link new security app"])},
    "popup_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download App"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect security app"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Brickwise Security App or create your personal one."])},
    "popup_underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a personal security app"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your public key"])}
  },
  "SecurityDetails": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public funding and advantages"])}
  },
  "SelectPartner": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your preferred provider"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a provider"])}
  },
  "Settings": {
    "delete_account_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's a pity that we couldn't convince you with our app - we would be very happy if you give us another chance or feedback. If you press this button, we will delete your account and data within 4-7 days. After that you will receive an email with a confirmation."])},
    "delete_account_modal_button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete_account_modal_button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_account_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Brickwise account and data"])},
    "experience_button_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not specify"])},
    "experience_button_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "experience_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adequacy"])},
    "experience_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are required by law to ask you about your knowledge and experience with various financial instruments and investment services."])},
    "experience_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information you provide will enable us to assess whether you have the necessary knowledge and experience to adequately assess the risks associated with the type of financial instruments or investment services."])},
    "experience_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information is voluntary. However, if you do not provide any or incomplete information, we cannot check the appropriateness for you. Therefore, you should provide this information out of your own interest."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])}
  },
  "SettingsList": {
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about Brickwise"])},
    "address_details_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential address"])},
    "address_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If any data has changed, please submit them to our support."])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "app_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App settings"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "change_passowd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "connected_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully connected"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_account_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Account"])},
    "direct_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive direct support"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "face_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face ID"])},
    "finger_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "how_can_we_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout account"])},
    "important_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All important documents"])},
    "important_reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preserve important notifications"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "invite_a_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
    "knowledge_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge & Experience"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "marketing_newsletter_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Notifications"])},
    "marketing_newsletter_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Notifications on Brickwise"])},
    "my_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My documents"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "not_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not connected"])},
    "payout_details_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to change your withdrawal account, please submit your new IBAN to our support."])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "questions_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions and answers"])},
    "realpartIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN for payounts"])},
    "residential_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential address"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "share_invite_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your invitation link"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "taxID_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your tax ID"])},
    "taxID_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax-ID"])},
    "update_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your information"])},
    "use_face_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Face ID to unlock"])},
    "use_finger_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Touch ID to unlock"])}
  },
  "ShareBadge": {
    "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out this real estate investment on Brickwise!"])}
  },
  "SignatureDocuments": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor es los geht, musst du noch unsere Vereinbarungen akzeptieren und signieren."])},
    "titleBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abschließen"])},
    "titleLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung"])}
  },
  "SupportCallBackModal": {
    "calendar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call with"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call back"])},
    "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will call you back at:"])},
    "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "select_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time slot"])},
    "select_weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select weekday"])},
    "send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "success_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call back added"])},
    "success_modal_button_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to calendar"])},
    "success_modal_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call back arranged"])},
    "success_modal_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We will contact you on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), " on your mobile number: ", _interpolate(_named("phone"))])},
    "success_modal_text_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll be in touch with you shortly!"])},
    "textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])}
  },
  "SupportView": {
    "account_opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account opening"])},
    "account_opening_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account opening"])},
    "builder_owner_modell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Builder Owner Modell"])},
    "call_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange a callback"])},
    "contact_us_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "investments_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "payment_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment account"])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate"])},
    "q_and_a_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions and answers"])},
    "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "search_dialog_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions and answers"])},
    "search_dialog_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We didn't find a suitable answer!"])},
    "search_dialog_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security app"])},
    "send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
    "tax_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Information"])},
    "trading_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading place"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
    "verification_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])}
  },
  "SupportWriteUsModal": {
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered email address is invalid"])},
    "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail adress"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will answer you to:"])},
    "send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "success_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "success_modal_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent"])},
    "success_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll be in touch with you shortly:"])},
    "textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])}
  },
  "TaxIdDetails": {
    "churchMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you member of a church?"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If data has changed, please send your changes to our support."])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax-ID"])},
    "mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your tax ID here."])},
    "successToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID has been added"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])}
  },
  "TaxIdModal": {
    "default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest into another product"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax-ID"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax-ID"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Austrian tax number is required to invest in this product. Please add an Austrian tax number."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also invest in a product for which the distributions are already taxed (capital gains tax deduction)."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Austrian Tax-ID is necessary in order to invest into this product."])}
  },
  "TradingButton": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "buy_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy more shares"])},
    "investmentNotPossible_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately an investment is not possible for this asset for the address provided"])},
    "reservationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a reservation"])},
    "reservationModal_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a reservation"])},
    "reservationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to your account to continue. If you have forgotten your password, click on Forgot password when logging in or contact support."])},
    "reservationModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "reservationModal_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation amount in €"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "userPendingModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will inform you by e-mail as soon as we have checked all your data."])},
    "userVerifyModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify account"])},
    "userVerifyModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the verification process before making your first investment."])},
    "userVerifyModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is not yet fully verified!"])}
  },
  "TradingOfferOverview": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit?"])},
    "delete_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "private_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private contact"])},
    "private_contact_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the buyer to arrange details about the transaction and payment processing."])},
    "private_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon conclusion of the sale, a brokerage fee in the amount of"])},
    "private_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be billed to your personal payment account. "])},
    "private_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve sale"])},
    "private_release_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the payment has been made, the shares can be transferred."])},
    "private_release_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sale has been approved"])},
    "private_release_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale approved"])},
    "private_sale_other_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer approval"])},
    "private_sale_other_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the buyer has also approved the transaction, the shares are transferred. "])},
    "private_sale_other_party_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The buyer has approved the transaction. The shares have been transferred."])},
    "private_secondparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance of the request by the seller "])},
    "private_secondparty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for the approval of the seller. After that, the desired shares are transferred. "])},
    "process_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction has been successful, the proceeds of the sale have been credited to the payment account."])},
    "sale_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance of the offer by an investor"])},
    "sale_accepted1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A buyer is interested in the offer"])},
    "sale_accepted1_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the sale."])},
    "sale_accepted_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be notified as soon as a buyer shows interest in the offer."])},
    "sale_accepted_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer confirmation"])},
    "sale_accepted_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A notification is sent as soon as the buyer has signed the transaction. The total proceeds will then be credited to the payment account. "])},
    "sale_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete sale"])},
    "sale_finish_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to complete the sale. The total proceeds will then be credited to the payment account. "])},
    "sale_finish_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale completed"])},
    "sale_finish_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The buyer has signed the transaction. The total proceeds were credited to the payment account."])},
    "sale_released": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign sale offer"])},
    "sale_released_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to sign the sale."])},
    "sale_released_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign sale"])},
    "sale_released_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the security app to sign the sale."])},
    "sale_released_second_party_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sale was signed with the Security App. "])},
    "sale_released_second_party_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer signed"])},
    "sale_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve sale"])},
    "sale_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the payment has been made, the shares can be transferred."])},
    "sale_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer signed"])},
    "sale_signed_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sale offer was signed with the Security App. "])},
    "selling_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale offer"])}
  },
  "TradingOverviewBox": {
    "purchase_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
    "sale_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale price"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share(s)"])}
  },
  "TradingStatus": {
    "iban_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not need a purpose for the payment. It may take a few days until your payment is received in our system."])}
  },
  "TransactionCard": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
    "buy_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase canceled"])},
    "notaryMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notary documents missing"])},
    "paymentMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment outstanding"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])}
  },
  "TransactionOverlay": {
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
    "buy_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABORTED PURCHASE"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission fee"])},
    "fiscalPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscal period"])},
    "footnote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tax in the amount of"])},
    "footnote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be automatically transferred to the tax office by the issuer."])},
    "investment_t_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment conditions"])},
    "ISIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN"])},
    "kest_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This transaction was corrected retroactively on 02/15/2022 and increased by the amount of capital gains tax originally deducted. The withheld capital gains tax was credited to your account on 02/15/2022 as part of this correction."])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout from payment account"])},
    "payout_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout amount"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution venue"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per share"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PURCHASED SHARES"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCOME"])},
    "rental_brutto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividend"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHARES SOLD"])},
    "sale_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale price"])},
    "share_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of shares"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "status_canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "status_inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "status_notaryMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notary documents missing"])},
    "status_paymentMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment"])},
    "status_pending_bankWire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for deposit"])},
    "status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "taxOfficeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Office"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security type"])}
  },
  "UpdateApp": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time for an update!"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install update"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start Brickwise, please install the latest version of our app."])}
  },
  "UpdatePassword": {
    "face_id_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your current password to enable Face ID for login."])},
    "face_id_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Face ID"])},
    "finger_id_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your current password to enable Touch ID for login."])},
    "finger_id_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Touch ID"])},
    "new_password_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
    "new_password_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "new_password_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password. Please follow the guidelines for a secure password."])},
    "new_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "new_password_validation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 10 characters and one capital letter"])},
    "old_password_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "old_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not correct"])},
    "old_password_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "old_password_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your current password. In the next step you can directly set a new one."])},
    "old_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "password_change_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error changing your password."])},
    "success_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to registration"])},
    "success_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! You can now log in with your new password."])},
    "success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed"])}
  },
  "UpdateSecurityApp": {
    "linkNewSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link multiple devices?"])},
    "linkNewSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only be linked to one security app at a time. If you disconnect from your current security app, it will no longer be available."])},
    "newSecurityApp_popupButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Private Security App"])},
    "newSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create aprivate Security App!"])},
    "newSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can link your own security app to your Brickwise account. "])},
    "newSecurityApp_popupText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you haven't created your own security app yet, check out the API documentation to help you code one."])},
    "newSecurityApp_popupText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! With your private security app, you can only create and accept private offers."])},
    "newSecurityApp_popupUnderlineButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View API Documentation"])},
    "removeSecurityApp_popupButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send E-Mail to the support"])},
    "removeSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery file not available?"])},
    "removeSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check again if your recovery file can be found. If you do not have access to your old device to download the recovery file again, it can only be reset with the help of our support for your safety."])}
  },
  "UserView": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
  },
  "VerificationOption": {
    "headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verification"])},
    "headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue the"])},
    "mobileOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue on Mobile"])},
    "mobileVerificationModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code with your phone"])},
    "mobileVerificationModal_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to verify your identity you will need to verify with a valid document. Mobile experience is reccomended"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to verify your identity you will need to verify with a valid document. Mobile experience is reccomended"])},
    "recommendedOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend the Mobile version"])},
    "webOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue on Web"])}
  },
  "VerificationStatus": {
    "failedMainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "failedTertiaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "failedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, etwas ist schiefgelaufen!"])},
    "pendingMainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "pendingTertiaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "pendingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! Die Überprüfung deiner Daten dauert länger"])},
    "successButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fortfahren"])},
    "successParagraph_comeBackLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen kurzen Augenblick..."])},
    "successParagraph_redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um einen kurzen Moment..."])},
    "successTitle_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["überprüfen"])},
    "successTitle_light1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir"])},
    "successTitle_light2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gerade\ndie Informationen"])}
  },
  "WebMenu": {
    "imprintLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "loggedInAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in as:"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "privacyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "registrationList_createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Account"])},
    "registrationList_InvestAndBenefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest and benefit"])},
    "registrationList_KnowledgeAndExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge and Experience"])},
    "registrationList_personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your personal data"])},
    "registrationList_SecurityApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link security app"])},
    "registrationList_VerifyIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your Identity"])}
  },
  "YieldCalculator": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yield calculator*"])},
    "offline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The yield calculator cannot be displayed at the moment."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* The values displayed are not an indicator of future returns or performance. Every investment is associated with considerable risks and can lead to the complete loss of the invested assets."])}
  },
  "ZipLoadingIndicator": {
    "zipLoadingIndicator_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please don't close the app"])},
    "zipLoadingIndicator_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading the data can take up to a minute depending on your internet connection. Please stay here until the upload has finished."])}
  },
  "texterify_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2025-01-16T07:48:57Z"])}
}