import { computed } from 'vue';
import store, { AppPlatform } from '@/store'
import { AxiosInstance } from 'axios';
import { InfoModalMutations, ModalDataInterface } from '@/store/modules/infoModal';
import router from '@/router';
//Interfaces & Enums
import { ShareLinkInterface } from '@/types/interfaces';
import { AppColorsInterface } from '@/types/interfaces';
import { User } from '@/store/modules/user';
import { AssetInterface } from '@/types/v2/asset/interfaces';
import { TSecurityType } from '@brickwise/asset-validation';
//Other
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links';

export function checkUserAbilityToInvestByAddress(asset: AssetInterface, user: User) {
  if(
    asset.data.investmentDetails.isAllowedPlz &&
    asset.data.investmentDetails.isAllowedPlz.length > 0 &&
    asset.data.investmentDetails.isAllowedDomicile &&
    asset.data.investmentDetails.isAllowedDomicile.length > 0
  ) {
    if(
      asset.data.investmentDetails.isAllowedDomicile.includes(user.personalInformation.domicile.toUpperCase()) &&
      asset.data.investmentDetails.isAllowedPlz.includes(user.personalInformation.PLZ)
    ) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function checkUserAbilityToInvestInAustrianEquityAssets(status: string, asset: AssetInterface, user: User) {
  //Check if user are able to invest in Austrian Equity Assets (Must have Austrian TaxId)
  if(
    status !== 'sell' &&
    (asset.data.investmentDetails.securityType === TSecurityType.participationRightEquity || asset.data.investmentDetails.securityType === TSecurityType.participationRightEquityBuilderOwner) &&
    asset.data.investmentDetails.spv?.address.countryCode === 'AUT' &&
    (typeof user.account.taxIds === 'undefined' || user.account.taxIds.filter(item => item.country.toUpperCase() === 'AUT').length === 0)
  ) {
    return true
  } else {
    return false
  }
}

export function checkGermanTaxIdRequirement(status: string, asset: AssetInterface, user: User) {
  //We need german taxId:
  //1. if user is from Germany
  //2. user want's to invest in an asset that is located in Germany
  //3. User don't have stored German TaxID
  if(
    status === 'buy' &&
    asset.data.investmentDetails.spv?.address.countryCode.toUpperCase() === 'DEU' &&
    user.personalInformation.domicile.toUpperCase() === 'DEU' &&
    (typeof user.account.taxIds === 'undefined' || user.account.taxIds.filter(item => item.country.toUpperCase() === 'DEU').length === 0)
  ) {
    return true
  } else {
    return false
  }
}

export function resolveAppConfigColors(colors: AppColorsInterface) {
  const root = document.querySelector(':root') as HTMLElement
  const colorsList = {
    '--brand500': colors.brand.brand500,
    '--brand400': colors.brand.brand400,
    '--brand300': colors.brand.brand300,
    '--brand200': colors.brand.brand200,
    '--brand100': colors.brand.brand100,
    '--primary500': colors.primary.primary500,
    '--primary400': colors.primary.primary400,
    '--primary300': colors.primary.primary300,
    '--primary200': colors.primary.primary200,
    '--primary100': colors.primary.primary100,
    '--success500': colors.system.success500,
    '--success200': colors.system.success200,
    '--error500': colors.system.error500,
    '--error200': colors.system.error200,
    '--highlight500': colors.highlight.highlight500,
    '--highlight400': colors.highlight.highlight400,
    '--highlight300': colors.highlight.highlight300,
    '--highlight200': colors.highlight.highlight200,
    '--highlight100': colors.highlight.highlight100,
    '--navy900': colors.navyShades.navyShades900,
    '--navy800': colors.navyShades.navyShades800,
    '--navy700': colors.navyShades.navyShades700,
    '--navy600': colors.navyShades.navyShades600,
    '--navy500': colors.navyShades.navyShades500,
    '--navy400': colors.navyShades.navyShades400,
    '--navy300': colors.navyShades.navyShades300,
    '--navy200': colors.navyShades.navyShades200,
    '--navy100': colors.navyShades.navyShades100,
    '--white': colors.navyShades.white,
    '--primary-CTA-label-color': colors.CTAColors.primaryCTALabelColor,
  };

  Object.entries(colorsList).forEach(v => {
    root.style.setProperty(v[0], v[1])
  })

  // Set Favicon on desktop
  if (store.state.appPlatform !== AppPlatform.app) {
    const link = document.createElement("link"),
    oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = store.state.appConfig.appIcon;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }
}

export function resolveAppConfigFonts(font: string) {
  if(!font) return
  const root = document.querySelector(':root') as HTMLElement
  root.style.setProperty('--primaryFont', font)
}

export function resolvePartnerStyles(partnerId: string) {
  const root = document.querySelector(':root') as HTMLElement
  let stylesList = {
    '--assetCardShadow': "0px 4px 4px rgba(38, 49, 59, 0.04)",
    '--assetCardBorder': "1px solid var(--navy300)",
    '--borderRadius': "16px"
  }

  if(partnerId === "oekostrom") {
    stylesList = {
      '--assetCardShadow': "unset",
      '--assetCardBorder': "1px solid var(--navy200)",
      '--borderRadius': ".5em"
    }
  }

  Object.entries(stylesList).forEach(v => {
    root.style.setProperty(v[0], v[1])
  })
}

export const initPlatformPartnerId = (partnerId: string, instances: Array<AxiosInstance>) => {
  if (!partnerId) return;
  for(let i=0; i<instances.length;i++) {
    instances[i].interceptors.request.use(function (config: any) {
      const storage = computed(() => store.state.user)
      //@ts-ignore
      const userPlatformProvider = storage.value.data.platformProvider
      config.headers.partner_id = userPlatformProvider ? userPlatformProvider : partnerId;
      return config;
    });
  }
  console.log('Init Platform PartnerId', partnerId);
};

export function checkMonthForNextSpecificDay(day: any) {
  const date = new Date();
  const dt = date.getDate();
  date.setDate(day);
  if (dt >= day) {
    date.setMonth(date.getMonth() + 1);
  }

  return new Date(date).toLocaleDateString('de-DE')
}

export function endOfQuarterDate(date: any) {
  const t = new Date(date.getTime()), year = t.getFullYear()
  t.setHours(0, 0, 0, 0);
  return [3, 6, 9, 12].map(month => new Date(year, month, 0)).find(q=>t<=q)
}

export function endOfYearDate(currentYear: any) {
  return new Date(currentYear, 11, 31).toLocaleDateString('de-DE')
}

export const generateAssetShareLink = async (id: string): Promise<string> => {
  const appConfig = store.getters.getAppConfig

  const config: ShareLinkInterface = {
    domainUriPrefix: appConfig.domainUriPrefix,
    uri: `${appConfig.appURL}/asset/${id}`,
    androidParameters: {
      packageName: appConfig.appIdentifierAndroid
    },
    iosParameters: {
      bundleId: appConfig.appIdentifierIos,
      appStoreId: appConfig.appStoreId
    }
  };
  return FirebaseDynamicLinks.createDynamicShortLink(config).then(link => link.value);
}

export async function requestLogin(that: any) {
  function handleRegisterClick() {
    store.commit(InfoModalMutations.closeInfoModal)
    router.push(`/${store.state.appPlatform}/register`)
  }

  function handleLoginClick() {
    store.commit(InfoModalMutations.closeInfoModal)
    router.push(`/${store.state.appPlatform}/login`)
  }

  const data = {
    header: that.$t('Menu.login_popup_text'),
    buttons: [
      { type: 'primary', text: that.$t('Menu.login_popup_button1'), function: handleRegisterClick },
      { type: 'tertiary', text: that.$t('Menu.login_popup_button2'), function: handleLoginClick }
    ]
  } as ModalDataInterface

  store.commit(InfoModalMutations.openInfoModal, data)
}
